<template>
    <div id="dashboard-steps-page" class="contact-centre">
      <v-alert zclass="text-capitalize text-break" v-if="donorCatchUpSfId" dense text color="primary">
        <b>Name:</b> {{ model.source }}: {{ model.receipt_name }}  <b>Donation:</b> $ {{ model.amount }} <b>Frequency:</b> {{ model.frequency_label }} <b>Payment Method:</b> {{ model.payment_type_label }} <b>Balance:</b> $ {{ model.balance || '0' }}
      </v-alert>
      <notify-modal :open="openNotifyModal" @proceed="openNotifyModal = false;currentPage++" @close="goBack('home', true)"/>
      <v-container class="grey lighten-5 pa-0 ma-0">
        <v-form  @submit.prevent="formSubmit">
          <base-card elevation="5" class="pa-0 ma-0" color="#f2f2f2" :loading="donorSearchLoading">
            <v-row no-gutters class="pa-0 ma-0" style="min-height: 550px;">
              <v-col cols="4" class="pa-0 ma-0 pa-7">
                  <step-sidebar @goHome="goBack('home')" @next="autoNext" :currentPage="currentPage" />
              </v-col>
              <v-col cols="8" class="pa-0 ma-0 pa-7 white">
                  <v-card-title v-if="donorCatchUpSfId" class="background--text text--darken-4 font-weight-bold pa-0 subtitle-1">Donation</v-card-title>
                  <v-card-title class="primary--text font-weight-bold d-inline-block pa-0" :class="{'title-border': currentPage !== 4 && !donorCatchUpSfId}">
                    <span v-if="currentPage !== 4 && !donorCatchUpSfId">Step {{ currentPage }}: </span>{{stepTitle}}
                  </v-card-title>
                  <step-one v-if="currentPage === 1" :valid="$v"/>
                  <step-two v-if="currentPage === 2" :v="$v"/>
                  <step-three :donorCatchUpSfId="donorCatchUpSfId" :stripeEl="stripe" v-if="currentPage === 3" :v="$v"/>
                  <step-four :slackLoading="sendSlackLoading" :storyLoading="sendStoryLoading" v-if="currentPage === 4" :storytag="storytag"
                    :storytype="storytype" :v="$v" @sendSlack="finalStepSave(1)"/>
              </v-col>
            </v-row>
            <step-footer :donorCatchUpSfId="donorCatchUpSfId" :slackLoading="sendSlackLoading" :storyLoading="sendStoryLoading" :v="$v" :currentPage="currentPage" @goHome="goBack('home', $event)" @back="goBack" @optionsOpen="optionsOpen = true" @followUP="modalOpen=true"/>
          </base-card>
          <FollowUpModal :modalOpen="modalOpen" @close="modalOpen=false" @save="followUpSave($event)"/>
        </v-form>
      </v-container>
      <v-dialog v-model="openStripModal" persistent scrollable width="400" height="400">
          <v-card class="full-width">
              <div id="stripe-iframe" class="full-width"></div>
          </v-card>
      </v-dialog>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { email, required } from "vuelidate/lib/validators";

    export default {
        name: "Home",
        metaInfo: {
        title: "Contact Centre",
        },
        components: {
            StepSidebar: () => import("@/pages/dashboard/StepSidebar"),
            StepFooter: () => import("@/pages/dashboard/StepFooter"),
            StepOne: () => import("@/pages/dashboard/components/step-forms/Step1"),
            StepTwo: () => import("@/pages/dashboard/components/step-forms/Step2"),
            StepThree: () => import("@/pages/dashboard/components/step-forms/Step3"),
            StepFour: () => import("@/pages/dashboard/components/step-forms/Step4"),
            Modal: () => import("@/components/common/modal"),
            FollowUpModal: () => import("@/pages/dashboard/components/FollowUpModal"),
            NotifyModal: () => import("@/pages/dashboard/components/NotifyModal"),
        },
        data() {
            return {
                currentPage: 1,
                stepTitle: 'Personal Information',
                stripe: null,
                modalOpen: false,
                storytag: [],
                storytype: [],
                sendSlackLoading: false,
                sendStoryLoading: false,
                donorCatchUpSfId: null,
                openStripModal: false,
                openNotifyModal: false,
            };
        },
        computed: {
            ...mapGetters(["donorSearchLists", "donorSearchLoading", "apiReqData", "followUpModel", "producerActCampModel"]),

            model: {
                get() {
                    return this.$store.getters["model"];
                },
                set(newValue) {
                    return this.$store.commit("setModel", newValue);
                },
            },
            previousSelectedDonor: {
                get() {
                    return this.$store.getters["previousSelectedDonor"];
                },
                set(newValue) {
                    return this.$store.commit("setPreviousSelectedDonor", newValue);
                },
            },
        },
        watch: {
            currentPage(newVal) {
                this.$store.commit('setCurrentStep', newVal);
            }
        },
        validations() {
            const defaultRequired = {
                    model: {
                        email: { email},
                        first_name: { required},
                        last_name: { required},
                    }
                }, catchupValid = { model: { receipt_name: { required } }},
                valid = this.donorCatchUpSfId ?catchupValid : defaultRequired;

            if (!this.donorCatchUpSfId && this.currentPage === 2 && !this.model.email && !this.model.suburb && !this.model.address1) {
                valid.model['email'] = {...valid.model['email'], ...{ required }};
            } else if (this.currentPage === 3) {
                if (['CQ', 'C', 'DT'].indexOf(this.model.paymentMethod) !== -1) {
                    valid.model['start_date'] = { required };
                    if (this.model.paymentMethod === 'CQ') {
                        valid.model['bsb'] = { required };
                        valid.model['account_no'] = { required };
                        valid.model['cheque_no'] = { required };
                        valid.model['drawer'] = { required };
                    }
                }
                valid.model['amount'] = { required };
            }
            return valid;
        },
        created() {
            this.getLatestIncentive();
            this.getPicklistData();
            if (this.$route && this.$route.path.includes('catchup-payment/')) {
                this.donorCatchUpSfId = this.$route.path.split('catchup-payment/')[1];
                this.currentPage = 3 ;
                this.stepTitle = 'How would you like to give?';
                this.getCatcupDetails();
            } else if((this.$route && (this.$route.path).match(new RegExp("/", "g")) || []).length > 1) {
                this.$router.push('/');
            }
            this.resetForm();
        },
        mounted() {
            this.stripe = window.Stripe(`${window.VUE_APP_STRIPE_PUBLISHABLE_KEY}`);
        },
        methods: {
            ...mapActions(["donatePaymentAmount", "storySave", "getCampaignLists", "updateFollowUp",
                "getSearchPicklist", "getActiveCampaign", "getLatestIncentive", 'donateSearch', 'getStripePaymentMethods',
                'getDonatSubBal', 'searchAccount', 'createPaymentIndent', 'createSubscription']),

            sfCampIDupdate() {
                const slug = this.$route.path.indexOf('/') !== -1 ? this.$route.path.slice(1) : null,
                    params = {};

                if (slug) {
                    this.model.referer_slug = slug;
                    params.slug = slug;
                }

                this.getActiveCampaign(params).then(resp => {
                    this.model.sf_campaign_id = resp.data.data.sf_campaign_once;
                    this.model.sf_campaign_name = resp.data.data.sf_campaign_once_name;
                    this.model.adv_campaign = { sf_id: this.model.sf_campaign_id, name: this.model.sf_campaign_name };
                });
            },
            getPicklistData() {
                const reqData = [{object: "Story__c", field: "Story_Type__c"}, {object: "Story__c", field: "Story_About__c"}];
                this.getSearchPicklist(reqData).then((response) => {
                    if (!_.isEmpty(response)) {
                        this.storytag = response.Story_About__c;
                        this.storytype = response.Story_Type__c;
                    }
                }).catch();
            },
            goBack (type=null, reset=false) {
                if (this.currentPage !== 1 && !type) {
                    this.currentPage -= 1 ;
                } else {
                    this.currentPage = 1;
                }
                if (['CQ', 'C', 'DT'].indexOf(this.model.paymentMethod) === -1) this.model.cardValidate =  false;
                if(reset) {
                    this.resetForm(true);
                } else this.titleChange();
            },
            titleChange () {
                switch (this.currentPage) {
                case 1:
                    this.stepTitle = 'Search for a match';
                    return '';
                case 2:
                    this.stepTitle = 'Update Details';
                    return '';
                case 3:
                    this.stepTitle = 'Process Donation';
                    return '';
                case 4:
                    this.stepTitle = 'Donation Selection';
                    return '';
                case 5:
                    this.stepTitle = 'Story Details';
                    return '';
                default:
                    this.stepTitle = 'Search for a match';
                    break;
                }
            },
            autoNext () {
                if (this.currentPage === 1) {
                    if (this.model.id) {
                        setTimeout(() => this.getBusinessLists({ contact_id: this.model.id}), 500);
                    }
                    this.currentPage += 1;
                    this.titleChange();
                }
            },
            formSubmit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }  else if (this.currentPage === 4) {
                    this.finalStepSave(0);
                } else if (this.currentPage === 3 && ['CQ', 'C', 'DT'].indexOf(this.model.paymentMethod) === -1) {
                    this.model.stripeErr = "";
                    const payload = {
                        amount: this.model.amount,
                        stripe_id: this.model.stripe_customer_id,
                        name: this.model.name || `${this.model.first_name} ${this.model.last_name}`,
                        email: this.model.email,
                        description: `${this.model.name || `${this.model.first_name} ${this.model.last_name}`} Single Charge`,
                        contact_id: this.model.id,
                        first_name: this.model.first_name,
                        last_name: this.model.last_name,
                        phone: this.model.phone,
                        address1: this.model.address1,
                        suburb: this.model.suburb,
                        state: this.model.state,
                        postcode:  this.model.postcode,
                    }
                    if(this.model.paymentFrequency === 'M') {
                        payload['start_date'] = this.model.start_date;
                        payload['description'] = `${this.model.name || `${this.model.first_name} ${this.model.last_name}`} Monthly Gift`;
                    }
                    this.getPaymentIntent(payload, this.model.paymentFrequency === 'M' ? 'createSubscription' : 'createPaymentIndent');
                } else if ((this.currentPage === 3 || (this.currentPage === 3 && ['CQ', 'C', 'DT'].indexOf(this.model.paymentMethod) !== -1))) {
                    this.onSubmit();
                } else {
                    if (this.currentPage < 2) {
                      if(this.model.id && !this.model.business) {
                        this.getBusinessLists({ contact_id: this.model.id });
                      }

                      if (this.previousSelectedDonor) {
                        const previous = JSON.stringify(_.pick(this.previousSelectedDonor, ['first_name', 'last_name']));
                        const current = JSON.stringify(_.pick(this.model, ['first_name', 'last_name']));
                        if (previous !== current) {
                          this.openNotifyModal = true;
                          return;
                        }
                      }
                    }

                    if((this.currentPage === 3 && ['CQ', 'C', 'DT'].indexOf(this.model.paymentMethod) !== -1)) {
                      this.currentPage = 4;
                    } else {
                      this.currentPage += 1;
                    }
                }
                this.titleChange();
            },
            getBusinessLists(reqData) {
                this.searchAccount(reqData).then((response) => {
                if (response.length) {
                    this.model.businessLists = response;
                    const defaultVal = this.model.sf_account_id ? response.find(item => item.sf_id === this.model.sf_account_id) : null;
                    this.model.business = defaultVal ? defaultVal : response[0];
                }
                }).catch();
            },
            getCatcupDetails() {
                this.donateSearch({ sf_id: this.donorCatchUpSfId }).then (resp => {
                    const response = resp.data.data ? resp.data.data[0] : null;
                    if (response) {
                        this.model = {...this.model, ...response};
                        this.model.first_name = this.model.donor_first_name;
                        this.model.last_name = this.model.donor_last_name;
                        this.model.name = this.model.donor_name;
                        this.model.suburb = this.model.donor_suburb;
                        this.model.paymentMethod = response.payment_type;
                        if (this.model.stripe_customer_id) this.getStripePaymentMethods(response.stripe_customer_id);
                        this.getDonatSubBal(this.model.payment_id).then(resp => this.model.balance = resp.balance);
                    }
                })
            },
            onSubmit() {
                if (['CQ', 'C', 'DT'].indexOf(this.model.paymentMethod) !== -1) {
                    this.$store.commit("updatePayReqModel", this.model);
                    this.donatePayment();
                } else {
                    this.confirmPaymentIntent(this.model.paymentMethod == 'CC' ? 'card' : 'au_becs_debit');
                }
            },
            getPaymentIntent(payload, key) {
                this.$store.commit("setStripLoading", true);
                this[key](payload).then(data => {
                    this.$store.commit("setStripLoading", false);
                    this.model.client_secret = data.client_secret;
                    this.model.setup_intent = data.setup_intent || false; // value only only return future date from api
                    if(data.customer_id) this.model.stripe_customer_id = data.customer_id;
                    if(data.contact_id) this.model.id = data.contact_id;  // for new contact member
                    if(data.subscription_id) this.model.subscription_id = data.subscription_id; // for monthly pay
                    this.$store.commit("updateModel", this.model);
                    this.confirmPaymentIntent(this.model.paymentMethod == 'CC' ? 'card' : 'au_becs_debit');
                }).catch(() => this.$store.commit("setStripLoading", false));
            },
            confirmPaymentIntent(key) {
                this.$store.commit("setAppLoading", true);
                const fieldName = this.$filters.titleCase(key.split('_').join(' '));
                const payIntent = {
                    return_url: `${window.VUE_APP_LIVE_URL}/3d-secure-completed`, //card
                    setup_future_usage: 'off_session'
                }
                const confirmPaymentOptions = {
                    payment_method:  this.model.existing_pay_id ? this.model.existing_pay_id : {
                        [key]: key === 'card' ? this.model.cardNumber : this.model.auBankAccount,
                        billing_details: { //sudebit
                            name: this.model.name || `${this.model.first_name} ${this.model.last_name}`,
                            email: this.model.email,
                        },
                    },
                    ...!this.model.setup_intent ? payIntent : {}
                };

                this.stripe[`confirm${fieldName.replace(/ +/g, '')}${this.model.setup_intent ? 'Setup' : 'Payment'}`](this.model.client_secret, confirmPaymentOptions)
                    .then((result) => {
                    this.$store.commit("setAppLoading", false);
                    if(result.error) {
                        this.$store.commit("snackbar/SHOW_MESSAGE", {
                            text: result.error.message || 'Payment Failed',
                            color: "error",
                        });
                    } else if(result.paymentIntent && result.paymentIntent.next_action) { // only for credit card
                        this.$store.commit("setStripLoading", true);
                        const self = this;
                        window.addEventListener('message', function(ev) {
                            self.$store.commit("setStripLoading", false);
                            if (ev.data === '3DS-authentication-complete') {
                                self.on3DSComplete();
                            }
                        }, false);
                    } else {
                        this.model.payment_intent_id = result[this.model.setup_intent ? 'setupIntent' : 'paymentIntent'].id;
                        this.updateStripeResponse(result);
                    }
                }).catch(err => this.$store.commit("setAppLoading", false));
            },
            updateStripeResponse(result) {
                this.$store.commit("setAppLoading", false);
                if (result.error) {
                    this.model.stripeErr = result.error.message;
                    this.$store.commit("updateModel", this.model);
                } else {
                    this.model.stripeErr = '';
                    this.$store.commit("updateModel", this.model);
                    this.donatePayment();
                }
            },
            on3DSComplete() {
                const self = this;
                $("#stripe-iframe").contents().remove();
                this.openStripModal = false;
                // Check the PaymentIntent
                this.stripe.retrievePaymentIntent(self.model.client_secret).then(function(result) {
                    if (result.error || (result.paymentIntent && result.paymentIntent.status === 'requires_payment_method')) {
                        self.$store.commit("snackbar/SHOW_MESSAGE", {
                            text: `Payment Failed!.`,
                            color: "error",
                        });
                    } else self.updateStripeResponse(result);
                });
            },
            donatePayment() {
                if (this.donorCatchUpSfId) {
                    this.apiReqData.existingLP_ID = this.model.id;
                    this.apiReqData.catchup = 1;
                }
                if(this.model.subscription_id) this.apiReqData.subscription_id = this.model.subscription_id;
                if (this.model.businessDonation) {
                    this.apiReqData.sf_account_id = this.model.sf_account_id;
                }
                this.$store.commit("setAppLoading", true);
                this.donatePaymentAmount(this.apiReqData).then((response) => {
                    if (!response || !response.data) {
                        return;
                    }
                    if (response.data.data.status !== 0) {
                        if (!this.donorCatchUpSfId) {
                            this.$helpers.lsPush("curDonate", response.data.data.id);
                            this.currentPage = 4;
                            this.titleChange();
                            if(this.followUpModel.type) {
                                const reqData = this.followUpModel;
                                reqData.donation_id =  response.data.data.id;
                                this.followUpSave(reqData);
                            }
                            return true;
                        }
                        this.donorCatchUpSfId = null;
                        this.currentPage = 1;
                        this.resetForm();
                        return this.$router.push('/');
                    } else {
                        this.currentPage = 3;
                    }
                }).catch(() => this.$store.commit("setAppLoading", false));
            },
            resetForm(initial=false) {
                this.$store.dispatch('refreshData');
                if (!initial && !this.donorCatchUpSfId && this.$helpers.getCurData("curDonate")) {
                    this.currentPage = 4;
                }
                if(initial) {
                  this.currentPage = 1;
                  this.openNotifyModal = false
                  this.$v.model.$reset();
                  this.$store.commit("snackbar/SHOW_MESSAGE", {
                    text: "Restart Successfully",
                    color: "success",
                  });
                }
                this.$store.commit('setCurrentStep', this.currentPage);
                this.titleChange();
                this.sfCampIDupdate();
            },
            followUpSave(reqData) {
                this.updateFollowUp(reqData).then(() => {
                    this.modalOpen = false;
                });
            },
            finalStepSave (slackvalue) {
                this.sendSlackLoading = slackvalue === 1 ? true : false;
                this.sendStoryLoading =  slackvalue === 1 ? false : true;
                const reqData = {
                    donation_id: this.$helpers.getCurData('curDonate'),
                    donor_tag: this.model.donor_tag,
                    story_tag: this.model.story_tag,
                    story_details: this.model.story_details,
                    send_slack: slackvalue,
                }
                this.storySave(reqData).then((response) => {
                    if (localStorage.getItem("pageLock") && localStorage.getItem("pageLock") === 'true') {
                        localStorage.removeItem("pageLock");
                        this.$router.push('info');
                    }
                    if (slackvalue === 0) {
                        this.$helpers.removeLSStorage('curDonate');
                        this.$store.dispatch('refreshData');
                        this.$v.model.$reset();
                        this.currentPage = 1;
                        this.titleChange();
                    }
                    this.sendSlackLoading = this.sendStoryLoading = false;
                }).catch(() => this.sendSlackLoading = this.sendStoryLoading = false);
            }
        }
    };
</script>

<style lang="scss">
    .selectable {
        .v-label {
            color: white !important;
        }
    }
</style>
